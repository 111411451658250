import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import LiftedBottomScreen from '../../../../ScreenWidthContainer/viewer/skinComps/LiftedBottomScreen/LiftedBottomScreen';
import HeaderContainer from '../../HeaderContainer';

const LiftedBottomScreenHeader: React.FC<Omit<
  IHeaderContainerProps,
  'skin'
>> = props => (
  <HeaderContainer {...props} skin={LiftedBottomScreen}></HeaderContainer>
);

export default LiftedBottomScreenHeader;
